<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">New Request</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form @submit="triggerSave" class="courses-form">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="course.courseName"
                label="Name of the course*"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="course.providerName"
                label="Name of the company*"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="course.amount"
                type="number"
                label="Amount of the course (full amount)*"
                required
              ></v-text-field>
            </v-col>
            <!-- Select Budget -->
            <v-col cols="12">
              <v-autocomplete
                v-model="course.budgetId"
                :items="budgetOptions"
                item-text="year"
                item-value="budgetId"
                label="Budget*"
                :disabled="userHasNoBudget"
                required
              ></v-autocomplete>
              <v-alert v-if="userHasNoBudget" color="danger">
                You don't have a budget assigned to you yet
              </v-alert>
            </v-col>
            <!-- Start Date -->
            <v-col cols="12">
              <label for="">Start Date *</label>
              <v-row justify="center">
                <!--TODO: Min and max dates should be calculated and formatted -->
                <v-date-picker
                  v-model="course.startDate"
                  color="grey"
                  header-color="dark"
                  class="mt-4"
                  min="2021-01-01"
                  max="2021-12-31"
                ></v-date-picker>
              </v-row>
            </v-col>
            <!-- End Date -->
            <v-col cols="12">
              <label for="">End Date *</label>
              <v-row justify="center">
                <!--TODO: Min and max dates should be calculated and formatted and validate that shouldn't be before the start date-->
                <v-date-picker
                  v-model="course.endDate"
                  class="mt-4"
                  color="grey"
                  header-color="dark"
                  min="2021-01-01"
                  max="2021-12-31"
                ></v-date-picker>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="course.description"
                label="Description"
                required
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <small>*indicates required field</small>
    </v-card-text>
    <!-- TODO: move buttons within the form -->
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="$emit('close')"> Close </v-btn>
      <v-btn
        :disabled="isSubmitBtnDisabled"
        color="primary"
        text
        @click="triggerSave"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'CoursesForm',

  props: {
    itemToEdit: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    course: {},
    isUpdate: false,
  }),

  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('users', ['getUser']),
    isSubmitBtnDisabled() {
      //TODO: this can be optimized it was done really quick
      return (
        !['courseName', 'providerName', 'amount', 'startDate', 'endDate'].every(
          (field) => !!this.course[field]
        ) || this.userHasNoBudget
      );
    },
    budgetOptions() {
      const currentUser = this.getUser(this.user.uid);
      return currentUser
        ? Object.keys(currentUser.userBudgets).map((year) => ({
            year,
            budgetId: currentUser?.userBudgets?.[year]?.budgetId,
          }))
        : [];
    },
    userHasNoBudget() {
      return !this.budgetOptions.length;
    },
  },

  watch: {
    itemToEdit() {
      this.updateData();
    },
  },

  created() {
    this.updateData();
  },

  methods: {
    triggerClose() {
      this.$emit('close');
      this.isUpdate = false;
      this.course = {};
    },
    triggerSave() {
      const mode = this.isUpdate ? 'update' : 'save';
      this.$emit(mode, { collection: 'courses', formData: this.course });
      this.isUpdate = false;
      this.course = {};
    },
    updateData() {
      if (Object.keys(this.itemToEdit).length) {
        this.isUpdate = true;
        this.course = this.itemToEdit;
      } else {
        this.isUpdate = false;
        this.course = {};
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.courses-form {
  width: 100%;
}
</style>
